<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row">
      <div class="ar-h2 mb4 mr2">
        Ссылки
      </div>
      <el-icon size="2em" color="#3290FF">
        <Link />
      </el-icon>
      <el-icon v-if="!linkEdit" size="2em" @click="linkEdit = true">
        <Edit />
      </el-icon>
    </div>
    <div class="links-form d-flex flex-row">
      <el-form id="form" label-position="left" label-width="4em" style="flex-basis: 50%;">
        <template v-if="!loaders.getVenue">
          <el-form-item>
            <template #label>
              <el-icon size="33" class="mr3">
                <VkMusic />
              </el-icon>
            </template>
            <div v-if="!linkEdit" class="promo-company__link">
              <a :href="form.vk"> {{ form.vk }} </a>
            </div>
            <el-input v-else v-model="form.vk" size="large" :readonly="isModerator" />
          </el-form-item>
          <el-form-item>
            <template #label>
              <el-icon size="33" class="mr3">
                <YandexMusic />
              </el-icon>
            </template>
            <div v-if="!linkEdit" class="promo-company__link">
              <a :href="form.yandex"> {{ form.yandex }} </a>
            </div>
            <el-input v-else v-model="form.yandex" size="large" :readonly="isModerator" />
          </el-form-item>
          <el-form-item>
            <template #label>
              <el-icon size="33" class="mr3">
                <Spotify />
              </el-icon>
            </template>
            <div v-if="!linkEdit" class="promo-company__link">
              <a :href="form.spotify"> {{ form.spotify }} </a>
            </div>
            <el-input v-else v-model="form.spotify" size="large" :readonly="isModerator" />
          </el-form-item>
          <el-form-item>
            <template #label>
              <el-icon size="33" class="mr3">
                <AppleMusic />
              </el-icon>
            </template>
            <div v-if="!linkEdit" class="promo-company__link">
              <a :href="form.apple"> {{ form.apple }} </a>
            </div>
            <el-input v-else v-model="form.apple" size="large" :readonly="isModerator" />
          </el-form-item>
          <el-form-item>
            <template #label>
              <el-icon size="33" class="mr3">
                <YoutubeMusic />
              </el-icon>
            </template>
            <div v-if="!linkEdit" class="promo-company__link">
              <a :href="form.youtube"> {{ form.youtube }} </a>
            </div>
            <el-input v-else v-model="form.youtube" size="large" :readonly="isModerator" />
          </el-form-item>
        </template>
        <div class="d-flex flex-row justify-end">
          <el-button v-if="!isModerator && linkEdit" type="primary" :disabled="loaders.postVenues" size="large" @click="postVenuesList">
            Сохранить
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  Edit,
  VkMusic,
  Spotify,
  AppleMusic,
  YoutubeMusic,
  YandexMusic,
} from '@/assets/icons/index.js';

import {
  ElMessage,
  ElLoading,
} from 'element-plus';

export default {
  name: 'PromoLinks',
  data: () => ({
    linkEdit: false,
    loaders: {
      getVenue: false,
      postVenues: false,
    },
    form: {
      yandex: null,
      vk: null,
      spotify: null,
      apple: null,
      youtube: null,
    },
    venueId: {
      yandex: null,
      vk: null,
      spotify: null,
      apple: null,
      youtube: null,
    },
  }),
  props: {
    release: Object,
  },
  components: {
    Edit,
    YandexMusic,
    VkMusic,
    Spotify,
    AppleMusic,
    YoutubeMusic,
  },
  mounted() {
    this.getVenuesList();
  },
  computed: {
    ...mapGetters(['isModerator']),
  },
  methods: {
    async getVenuesList() {
      this.loaders.getVenue = true;
      const loading = ElLoading.service({
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
        target: '#form',
      });
      await this.$axios.get('/music-venue/get-all-music-venues')
        .then((res) => {
          const { data } = res;
          for (let i = 0; i < data.length; i++) {
            const venue = data[i];
            this.venueId[venue.name.toLowerCase()] = venue.id;
          }
        }).then(() => {
          this.getVenue();
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        })
        .finally(() => {
          loading.close();
          this.loaders.getVenue = false;
        });
    },
    async getVenue() {
      const { data } = await this.$axios.get(`/music-venue/get-release-venue-by-id/${this.release.id || this.release.releaseId}`).catch(() => {
        this.linkEdit = true;
      });
      for (let i = 0; i < data.items.length; i++) {
        const venue = data.items[i];
        this.form[venue.name.toLowerCase()] = venue.link;
        const hasLinks = Object.values(this.form).filter((it) => it).length > 0;
        this.linkEdit = !hasLinks;
      }
    },
    async postVenuesList() {
      this.loaders.postVenues = true;
      const items = [];
      for (const venue in this.form) {
        if (this.form[venue]) {
          items.push({
            releaseId: this.release.id,
            musicVenueId: this.venueId[venue],
            link: this.form[venue],
          });
        }
      }
      const req = {
        items,
      };
      await this.$axios.post('/music-venue/create-release-venue', req)
        .then(() => {
          ElMessage({ message: 'Cсылки успешно обновлены', type: 'success' });
          this.getVenue();
          this.linkEdit = false;
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        })
        .finally(() => {
          this.loaders.postVenues = false;
        });
    },
    close() {
      this.visible = false;
      this.form = {
        yandex: null,
        vk: null,
        spotify: null,
        apple: null,
        youtube: null,
      };
    },
  },
};
</script>

<style lang="scss">
.promotion-modal{

  .el-form-item__label{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
&__btn{

}
.el-dialog{
  &__header{
    font-size: 1.7777777777777777em;
    color:white;
    padding:1.6666666666666667em 2.5em  0.8333333333333334em 2.5em!important;
    margin:0;

    &btn{
      font-size: 0.8em;
      top: 2em !important;
      right: 1.6666666666666667em !important;
    }
  }

  &__body{
    padding:60px 70px 1.1111111111111112em 70px!important;
  }

  &__footer{
    display: flex;
    justify-content: center;
    padding:1.1111111111111112em 2.5em 1.6666666666666667em 1.6666666666666667em!important;
  }
}
}
</style>
