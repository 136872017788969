<template>
  <div>
    <div v-if="release" class="promo-company__info-wrapper  ar-card pa4" style="display:flex; flex-direction:row">
      <div class="d-flex flex-row promo-company__album-info">
        <div class="">
          <el-image :src="release.imageThumbPath|| release.imagePath" class="mb2">
            <template #error>
              <div class="image-slot">
                <el-icon size="1.4em">
                  <Catalog />
                </el-icon>
              </div>
            </template>
          </el-image>
          <div class="promo-company__album-info--info">
            <div class="promo-company__album-info--name ar-h2 mb2">
              {{ release.name || 'Нет названия' }}
            </div>
            <div class="promo-company__album-info--artist ar-subheader-1">
              {{ release.headArtists.join(', ') || 'Нет исполнителя' }}
            </div>
          </div>
        </div>
      </div>
      <div class="promo-company__form">
        <el-tabs v-if="promo" v-model="activeTabName">
          <el-tab-pane label="Описание релиза" name="description">
            <!-- <div v-if="promo.description" class="promo-company__info-wrapper">
              {{ promo.description || null }}
            </div> -->
            <el-input
              v-model="form.description"
              placeholder="Описание"
              :rows="6"
              type="textarea"
            />
          </el-tab-pane>
          <el-tab-pane label="Маркетинговая инофрмация" name="marketingInfo">
            <!-- <div v-if="promo.marketingInfo" class="promo-company__info-wrapper">
              {{ promo.marketingInfo || null }}
            </div> -->
            <el-input
              v-model="form.marketingInfo"
              placeholder="Описание"
              :rows="6"
              type="textarea"
            />
          </el-tab-pane>
          <el-tab-pane label="Биография артиста" name="artistBio">
            <!-- <div v-if="promo.artistBio" class="promo-company__info-wrapper">
              {{ promo.artistBio || null }}
            </div> -->
            <el-input
              v-model="form.artistBio"
              placeholder="Описание"
              :rows="6"
              type="textarea"
            />
            <div v-if="isModerator" style="margin-top:15px">
              <el-button type="primary" :loading="loaders.accept" :disabled="loaders.deny" @click="approvePressRelease();">
                Опубликовать
              </el-button>
              <el-button type="danger" :disabled="loaders.accept" :loading="loaders.deny" @click="denyPressRelease();">
                Отклонить
              </el-button>
            </div>
            <div v-else style="margin-top:15px" class="d-flex flex-row justify-end">
              <el-button type="primary" size="large" plain :disabled="!canPost" @click="postPressRelease();">
                Далее
              </el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-if="promo?.release" class="ar-card pa4">
      <PromotionLinks :release="promo.release" />
    </div>
  </div>
</template>
<script>
import {
  ElMessage,
  // ElLoading,
} from 'element-plus';
import { realeseType } from '@/utilities/enums';
import { mapGetters } from 'vuex';
import PromotionLinks from '../components/promo/PromotionLinks.vue';

export default {

  name: 'PromoCompany',
  props: {
    id: String,
  },
  components: {
    PromotionLinks,
  },
  data: () => ({
    linkEdit: false,
    activeTabName: 'description',
    realeseType,
    promo: null,
    loaders: {
      accept: false,
      deny: false,
    },
    linkForm: {
      yandex: null,
      vk: null,
      spotify: null,
      apple: null,
      youtube: null,
    },
    form: {
      description: '',
      marketingInfo: '',
      artistBio: '',
    },
  }),
  async mounted() {
    this.getPromo();
  },
  computed: {
    ...mapGetters(['isModerator']),
    canPost() {
      return !!this.form.description && !!this.form.marketingInfo && !!this.form.artistBio;
    },
    release() {
      return this.promo?.release;
    },
  },
  methods: {
    async approvePressRelease() {
      try {
        this.loaders.accept = true;
        await this.$axios.post(`/press-release/accept-by-press-release-id/${this.promo.id}`);
        ElMessage({ message: 'Пресс-релиз одобрен', type: 'success' });
        this.$router.push('/promo');
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loaders.accept = false;
      }
    },
    async denyPressRelease() {
      try {
        const params = { comment: '' };
        this.loaders.deny = true;
        await this.$axios.post(`/press-release/deny-by-press-release-id/${this.promo.id}`, params);
        ElMessage({ message: 'Пресс-релиз отклонён', type: 'success' });
        this.$router.push('/promo');
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loaders.deny = false;
      }
    },
    async postPressRelease() {
      const params = { ...this.form };
      try {
        await this.$axios.post(`/press-release/create-press-release?releaseId=${this.promo.id}`, params);
        ElMessage({ message: 'Пресс-релиз создан', type: 'success' });
        this.$router.push('promotion-list');
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
    getGenreFullName(genre) {
      if (!genre) {
        return '—';
      }
      return `${genre.parent ? `${genre.parent.name} / ` : ''}${genre.name}`;
    },
    async getPromo() {
      try {
        const { data } = await this.$axios.get(`/press-release/get-press-release-by-release-id?releaseId=${this.id}`);
        this.promo = data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
  },
};

</script>

<style lang="scss">
.promo-company {
  &__info-wrapper {
    position: relative;
    background-color: $primaryWhite;
    display: flex;
    flex-direction: column;
    margin-bottom:1em;
  }

  &__link a{
    color:$primaryBlue !important;
  }

  &__album-info {
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-basis: auto;

    .el-image{
      width: 16em !important;
      height:16em !important;
      border-radius:1em!important;

      .image-slot{
        background-color: #151515;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &--info{
      padding: 0.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--name{
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-weight: 500;
    }

    &--artist{
      color:$subGrey1;
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
    &--type{
      display: flex;
      align-items: center;
      font-size: 1em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-weight: 300;
    }
  }
  &__form{
    flex-basis: 70%;
    margin-left: 4em;
  }
  &__btn{
      margin-left: 0.3rem;
      padding: 0.25rem;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      width:2rem;
      height:2rem;
      border-radius: 50%;
      font-weight:500;

      .el-icon{
        color: $primaryBlue;
        font-size:1.3rem!important;
      }

      &:hover{
        background-color: rgba(255,255,255,.2);
      }
    }
  &__data {
    width:70%;

    &--fields{
      font-size: 1em;
      display: flex;
      flex-direction: row;

      & :first-child{
        width:4em;
        margin-right: 0.5em;
        color: $primaryBlue;
      }
      & :last-child{
        font-weight: 300;
        margin-left: 0.5em;
      }
    }
  }
}
</style>
